/*------------------------------------------------------------
# Global classes
------------------------------------------------------------*/
.flex {
    display: flex;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: hsl(0, 0%, 90%);
}

@font-face {
    font-family: "mountains";
    /*src: url(../fonts/beyond_the_mountains.ttf);*/
    src: url(../fonts/beyond_the_mountains.otf);
    font-weight: 400;
}

.d-col-n {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

/*------------------------------------------------------------
# site-header
------------------------------------------------------------*/
/*
.home-page {
    background: url(images/middle-cover.jpg) no-repeat;
}
*/

.site-header {
    position: relative;
    width: 100%;
}

.site-header figure {
    margin: 0;
    padding: 0;
}

.site-header figure img {
    max-width: 100%;
}

/*------------------------------------------------------------
## header-bar
------------------------------------------------------------*/
.site-header .header-bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 40px 0;
}

.header-bar .site-branding {
    margin-bottom: 0;
    font-size: 50px;
    font-weight: 800;
    color: hsl(0, 0%, 100%);
    text-decoration: none;
    font-family: "mountains";
}

/*------------------------------------------------------------
### site-navigation
------------------------------------------------------------*/
/*
  Offcanvas Menu
----------------------------------------*/

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.offcanvas-title {
    font-family: "mountains";
    color: #03070c;
    font-size: 28px;
}

.offcanvas-body.bg-grey,
.offcanvas-header.bg-grey {
    background:hsla(0, 0%, 0%, 0.6);
}

.offcanvas-header .btn-close {
    color: #fff;
}

.offcanvas-body ul li {
    overflow: hidden;
    list-style: none;
    color: #fff;
}

.offcanvas-body ul li a {
    font-size: 26px;
}

.site-navigation.hidden {
    position: absolute;
    top: -11px;
    right: 15px;
    z-index: 9999;
    width: 100%;
    height: 22px;
    overflow: hidden;
    list-style: none;
    transition: all .35s;
    color: hsla(0, 0%, 0%, 0.6);
}

.site-navigation.hidden ul {
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 9999;
    width: 320px;
    height: 100vh;
    overflow-x: scroll;
    padding: 30px;
    margin: 0;
    background: #050505;
    transition: all 0.35s;
}

.site-navigation.show ul {
    left: 0;
}

.site-navigation.hidden ul li {
    display: block;
    padding: 15px 0;
}

.site-navigation.hidden ul li a,
.site-navigation.hidden ul li button {
    display: block;
    color: #fff;
    transition: all 0.35s;
    text-decoration: none;
    font-size: 18px;
}

.site-navigation.hidden ul li .btn:hover {
    color: #03070c;
    background-color: #f47b0a;
    border-color: #f47b0a;
}

.site-navigation.hidden ul li a {
    padding-left: 40px!important;
}

.site-navigation.hidden ul li a:nth-last-of-type(1) {
    padding-left: 12px!important;
}

@media screen and (min-width: 992px) {
    .header-bar .site-branding {
        margin-left: 35px;
    }

    .site-navigation.hidden {
        position: relative;
        top: auto;
        right: auto;
        height: auto;
        padding-right: 35px;
        background: transparent;
    }

    .site-navigation.hidden ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;
        padding: 0;
        overflow: auto;
        background: transparent;
    }

    .site-navigation.hidden ul li {
        padding: 0;
    }

    .site-navigation.hidden ul li:nth-last-of-type(1) {
        padding-left: 60px;
    }

    .site-navigation.hidden ul li a {
        color: #fff;
    }
}

/*------------------------------------------------------------
# hero
-----------------------------------------------------------*/
.hero-content {
    padding: 200px 0;
    background: linear-gradient(0deg, hsla(0, 0%, 5%, 0.7), hsla(0, 0%, 5%, 0.5)),url('../img/cover.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-content.hero-video .entry-header {
    background: hsla(0, 0%, 5%, 0.1);
}

.hero-content .entry-header,
.hero-content .entry-header-subline  {
    position: relative;
    text-align: center;
}

.hero-content .entry-header h2 {
    padding: 50px;
    margin: 0;
    font-family: mountains;
    font-size: 20vw;
    background: -webkit-linear-gradient(#ffffff, #cecece);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-content .entry-header-subline h2 {
    padding: 50px;
    margin: 0;
    font-family: mountains;
    font-size: 15vw;
    background: -webkit-linear-gradient(#ffffff, #cecece);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-content .entry-header .entry-meta-date {
    position: absolute;
    width: 100%;
    top: 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: hsl(0, 0%, 90%);
    text-align: center;
}

.hero-content .sponsor-vitacola h2 {
    padding: 50px;
    margin: 0;
    font-family: mountains;
    font-size: 32px;
    text-align: center;
    color: hsl(0, 0%, 90%);
}

@media screen and (min-width: 992px) {
    .hero-content .entry-header h2 {
        font-size: 172px;
    }

    .hero-content .entry-header-subline h2 {
        font-size: 90px;
    }
    
    .hero-content .entry-header .entry-meta-date {
        top: 60px;
    }
}

/*------------------------------------------------------------
## teaser
------------------------------------------------------------*/
.teaser-content-section {
    height: 100vh;
}

.teaser-content,
.teaser-content .teaser-main-content {
    height: 100%;
}

.teaser-content #teaser-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.teaser-content .teaser-headline,
.teaser-content .teaser-headline-subline  {
    position: relative;
    text-align: center;
}

.teaser-content .teaser-headline h2,
.teaser-content .teaser-headline-subline h2  {
    margin: 0;
    font-family: mountains;
}

.teaser-content .teaser-headline h2 {
    padding: 25px 25px 10px 25px;
}

.teaser-content .teaser-headline-subline h2 {
    padding: 0px 25px 70px 25px;
}

.teaser-content .teaser-bottom-links {
    position: relative;
    text-align: center;
}

.teaser-content .teaser-bottom-links a {
    text-decoration: none;
    color: #fff;
}

.teaser-content .teaser-copyright {
    font-size: 12px;
}

/*------------------------------------------------------------
## countdown
------------------------------------------------------------*/
.countdown {
    margin-top: 40px;
}

.countdown-holder {
    min-width: 150px;
    margin-bottom: 30px;
}

.countdown-holder .dday,
.countdown-holder .dhour,
.countdown-holder .dmin,
.countdown-holder .dsec {
    display: block;
    font-size: 72px;
    font-weight: 600;
    line-height: 1;
    color: hsl(0, 0%, 100%);
}

.countdown-holder .dlimiter {
    display: block;
    font-size: 50px;
    line-height: 1;
    color: hsl(0, 0%, 100%);
}

.countdown-holder label {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: hsl(0, 0%, 100%);
}

/*------------------------------------------------------------
## hero-content entry-footer
------------------------------------------------------------*/
.hero-content .entry-footer {
    margin-top: 125px;
    text-align: center;
}

.hero-content .entry-footer .btn {
    background: hsl(0, 0%, 100%);
    padding: 16px 40px;
    border-radius: 0;
    margin-right: 20px;
    color: hsl(0, 0%, 17%);
    font-weight: 600;
}

.hero-content .entry-footer a:hover, 
.hero-content .entry-footer .current {
    background: -webkit-linear-gradient(#f47b0a, #ff5825);
    color: hsl(0, 0%, 0%);
}

/*------------------------------------------------------------
# The Lineup Artists - Headliners
------------------------------------------------------------*/
/*
.content-section {
    min-height: 4640px;
    width: 100%;
    padding-bottom: 120px;
    background: url("images/middle-cover.jpg") no-repeat;
    background-size: 100% auto;
}
*/

.content-section {
    /*
    background: linear-gradient(217deg, #cecece, #d4d4d4, #dadada, #e0e0e0, #e7e7e7, #ededed, #f3f3f3, #f9f9f9);
    background-size: 1600% 1600%;

    -webkit-animation: menuBackground 21s ease infinite;
    -moz-animation: menuBackground 21s ease infinite;
    -o-animation: menuBackground 21s ease infinite;
    animation: menuBackground 21s ease infinite;
    */
    padding-bottom: 120px;
}

@-webkit-keyframes menuBackground {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}
@-moz-keyframes menuBackground {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}
@-o-keyframes menuBackground {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}
@keyframes menuBackground {
    0%{background-position:94% 0%}
    50%{background-position:7% 100%}
    100%{background-position:94% 0%}
}

.content-section .middle-cover {
    margin: 0;
    padding: 0;
    position: relative;
}

.content-section .middle-cover img {
    max-width: 100%;
}

/*------------------------------------------------------------
## lineup-artists-headline
------------------------------------------------------------*/
.philosophie-headline,
.lineup-artists-headline {
    margin-top: 115px;
}

.philosophie-headline .entry-title p,
.lineup-artists-headline .entry-title p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: hsl(0, 0%, 48%);
    font-family: 'mountains';
}

.philosophie-headline .entry-title h2,
.lineup-artists-headline .entry-title h2 {
    width: 100%;
    font-size: 48px;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
}

/*------------------------------------------------------------
## philosopie
------------------------------------------------------------*/
.philosophie,
.lineup-artists {
    padding-top: 80px;
}

.philosophie .featured-image,
.lineup-artists .featured-image {
    margin: 0;
    padding: 0;
}

.philosophie .featured-image,
.lineup-artists .featured-image {
    width: 100%;
}

.philosophie .featured-image img,
.lineup-artists .featured-image img {
    width: 100%;
    max-width: 100%;
}

/*------------------------------------------------------------
### lineup-artists-wrap
------------------------------------------------------------*/
.philosophie .philosophie-wrap,
.lineup-artists .lineup-artists-wrap {
    margin-top: 80px;
}

.philosophie .philosophie-wrap .philosophie-description,
.lineup-artists .lineup-artists-wrap .lineup-artists-description {
    position: relative;
    width: 100%;
}

.philosophie .philosophie-wrap .philosophie-description-container,
.lineup-artists .lineup-artists-wrap .lineup-artists-description-container {
    position: relative;
    padding: 30px;
    background: hsl(0, 0%, 100%);
    box-shadow: 50px 40px 115px rgba(0, 0, 0, 0.1);
}

.philosophie-description .entry-title,
.lineup-artists-description .entry-title {
    font-size: 30px;
    font-weight: 600;
    color: hsl(0, 0%, 9%);
}

.philosophie-description .entry-content,
.lineup-artists-description .entry-content {
    margin-top: 20px;
    font-size: 15px;
    color: hsl(0, 0%, 30%);
    line-height: 2;
}

.philosophie .box-link,
.lineup-artists-wrap .box-link {
    position: absolute;
    margin: 0;
    padding: 0;
    top: -24px;
    right: 50px;
    z-index: 999;
}

.philosophie-wrap .box-link img,
.lineup-artists-wrap .box-link img {
    opacity: 0.75;
    -webkit-filter: drop-shadow(3px 2px 0 rgba(0,0,0,0.2));
    filter: drop-shadow(3px 2px 0 rgba(0,0,0,0.2));
}

.philosophie-wrap .box-link img:hover,
.lineup-artists-wrap .box-link img:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    opacity: 1;
    transition: .8s all ease;
}

@media screen and (min-width: 768px) {
    .philosophie .philosophie-wrap,
    .lineup-artists .lineup-artists-wrap {
        margin-top: 50px;
    }

    .philosophie .featured-image,
    .lineup-artists .featured-image {
        max-width: 360px;
    }

    .philosophie .philosophie-wrap .philosophie-description,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description {
        width: calc(100% - 360px);
    }

    .philosophie .philosophie-wrap .philosophie-description-container {
        position: absolute;
        z-index: 99;
        top: 85px;
        left: -75px;
        width: calc(100% + 75px);
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container {
        position: absolute;
        z-index: 99;
        left: -75px;
        width: calc(100% + 75px);
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.tectro-unity {
        top: 0px;
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.acina,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.justin-prince {
        top: 15px;
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.darley-jefferson,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.die-genossen-fett,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.handrix {
        top: 30px;
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.oscar-house,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.tommyrich,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.tart {
        top: 45px;
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.blvck-crowz {
        top: 50px;
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.tart p,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container.tectro-unity p {
        white-space: pre-wrap;
    }

    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container {
        top: 100px;
    }

    .philosophie .philosophie-wrap:nth-of-type(even) .philosophie-description-container,
    .lineup-artists .lineup-artists-wrap:nth-of-type(even) .lineup-artists-description-container {
        right: -75px;
        left: auto;
    }

    .philosophie .philosophie-wrap:nth-last-of-type(even) .box-link,
    .lineup-artists .lineup-artists-wrap:nth-of-type(even) .box-link {
        right: auto;
        left: 50px;
    }
}

@media screen and (min-width: 992px) {
    .philosophie .philosophie-wrap .philosophie-description-container,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container {
        padding: 50px;
    }
}


@media screen and (min-width: 1200px) {
    .philosophie .philosophie-wrap .philosophie-description-container,
    .lineup-artists .lineup-artists-wrap .lineup-artists-description-container {
        max-width: 660px;
    }

    .philosophie .philosophie-wrap:nth-last-of-type(1),
    .lineup-artists .lineup-artists-wrap:nth-last-of-type(1) {
        margin-left: 80px;
    }
}

/*------------------------------------------------------------
# the-complete-lineup
------------------------------------------------------------*/
.the-complete-lineup {
    margin-top: 115px;
}

.the-complete-lineup .entry-title p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: hsl(0, 0%, 48%);
}

.the-complete-lineup .entry-title h2 {
    width: 100%;
    margin: 0;
    font-size: 48px;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
}

/*------------------------------------------------------------
## the-complete-lineup-artists
------------------------------------------------------------*/
.the-complete-lineup-artists {
    position: relative;
    margin-top: 110px;
    height: auto;
}

.the-complete-lineup-artists .artist-single {
    margin-bottom: 0px;
}

.the-complete-lineup-artists .artist-single:nth-of-type(even) {
    margin-top: 120px;
}

.the-complete-lineup-artists .artist-single figure {
    margin: 0;
    padding: 0;
    width: 100%;
}

.the-complete-lineup-artists .artist-single .box-link {
    position: absolute;
    top: 15px;
    left: 232px;
    cursor: pointer;
}

.the-complete-lineup-artists .artist-single .featured-image img {
    max-width: 100%;
    height: auto;
    width: 255px;
}

.the-complete-lineup-artists .artist-single .featured-image a {
    display: block;
}

.the-complete-lineup-artists .artist-single h2 {
    margin-top: 38px;
    font-size: 24px;
    font-weight: 600;
    color: hsl(0, 0%, 2%);
    text-align: center;
}

@media screen and (min-width: 768px) {
    .the-complete-lineup-artists .artist-single:nth-child(6) {
        margin-top: 0;
    }
}


@media screen and (min-width: 1200px) {
    .the-complete-lineup-artists .artist-single:nth-child(1) {
        margin-top: 2px;
    }

    .the-complete-lineup-artists .artist-single:nth-child(2) {
        margin-top: 90px;
    }

    .the-complete-lineup-artists .artist-single:nth-child(3) {
        margin-top: 48px;
    }

    .the-complete-lineup-artists .artist-single:nth-child(4) {
        margin-top: 0;
    }

    .the-complete-lineup-artists .artist-single:nth-child(5) {
        margin-top: 75px;
    }

    .the-complete-lineup-artists .artist-single:nth-child(6) {
        margin-top: 165px;
    }

    .the-complete-lineup-artists .artist-single:nth-child(7) {
        margin-top: 25px;
    }

    .the-complete-lineup-artists .artist-single:nth-child(8) {
        margin-top: 75px;
    }
}

/*------------------------------------------------------------
# home-page-last-news
------------------------------------------------------------*/
.home-page-last-news {
    margin-top: 220px;
}

.home-page-last-news .entry-title p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: hsl(0, 0%, 48%);
}

.home-page-last-news .entry-title h2 {
    width: 100%;
    margin: 0;
    font-size: 48px;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
}

/*------------------------------------------------------------
## home-page-last-news-wrap
------------------------------------------------------------*/
.home-page-last-news-wrap {
    margin-top: 110px;
}

.home-page-last-news-wrap .box-link-date {
    position: absolute;
    top: 30px;
    left: 0;
    padding: 10px 25px;
    background: -webkit-linear-gradient(#00d0ff, #25ffbf);
    font-size: 20px;
    font-weight: 500;
}

.home-page-last-news-wrap .box-link-date a {
    color: hsl(0, 0%, 100%);
    text-decoration: none;
}

.home-page-last-news-wrap .content-wrapper {
    background: hsl(0, 0%, 100%);
    box-shadow: 10px 40px 115px 27px rgba(0, 0, 0, 0.1);
}

.home-page-last-news-wrap .featured-image {
    margin: 0;
    padding: 0;
    box-shadow: 10px 40px 115px 27px rgba(0, 0, 0, 0.1);
}

.home-page-last-news-wrap .featured-image img {
    max-width: 100%;
}

.home-page-last-news-wrap .entry-content {
    padding: 35px 45px;
}

.home-page-last-news-wrap .entry-content .entry-header h2 {
    font-size: 24px;
    font-weight: 600;
    color: hsl(0, 0%, 9%);
}

.home-page-last-news-wrap .entry-content .entry-header h2 a {
    color: hsl(0, 0%, 9%);
}

.home-page-last-news-wrap .entry-content .entry-meta {
    margin-top: 6px;
    font-size: 12px;
    color: hsl(0, 0%, 9%);
    font-weight: 500;
}

.home-page-last-news-wrap .entry-content .entry-meta a {
    color: hsl(0, 0%, 10%);
}

.home-page-last-news-wrap .entry-content .entry-description {
    margin-top: 25px;
    font-size: 15px;
    color: hsl(0, 0%, 60%);
    line-height: 2;
}

.home-page-last-news-wrap .entry-content .entry-meta span {
    margin-right: 10px;
}

/*------------------------------------------------------------
# site-footer
------------------------------------------------------------*/
.site-footer {
    position: relative;
    padding: 40px 0 30px;
    background: url(../img/footer-cover.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.site-footer::before {
    position: absolute;
    content: " ";
    height: 100%;
    width: 100%;
    z-index: 3;
    background: hsla(0, 0%, 5%, 0.7);
    top: 0;
    left: 0;
}

.site-footer .footer-cover-title {
    min-height: 430px;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .site-footer .footer-cover-title {
        min-height: 330px;
    }
}

@media screen and (max-width: 767px) {
    .site-footer .footer-cover-title {
        min-height: 700px;
    }
}

.site-footer .footer-cover-title h2 {
    font-size: 15vw;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
    font-family: "mountains";
    color: hsl(0, 0%, 100%);
    mix-blend-mode: overlay;
    z-index: 2;
}

/*------------------------------------------------------------
## .site-footer footer-content-wrapper
------------------------------------------------------------*/
.footer-content-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 4;
    top: 130px;
    left: 0;
    padding: 6px 0;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .footer-content-wrapper {
        top: 5px;
    }
}

.footer-content-wrapper .flex {
    flex-direction: column;
}

.footer-content-wrapper .entry-title {
    margin-top: 25px;
}

.footer-content-wrapper .entry-mail {
    margin-top: 45px;
    font-size: 16px;
    font-weight: 700;
    color: hsl(0, 0%, 100%);
}

.footer-content-wrapper .entry-mail a,
.footer-content-wrapper .footer-links a {
    text-decoration: none;
    color: hsl(0, 0%, 100%);
}

.footer-content-wrapper .copyright-info,
.footer-content-wrapper .footer-links {
    margin-top: 20px;
    font-size: 12px;
    color: hsl(0, 0%, 100%);
}

.footer-content-wrapper .social-links a {
    font-size: 12px;
    color: hsl(0, 0%, 100%);
    text-decoration: none;
}

.footer-content-wrapper .footer-col {
    margin-top: 32px;
}

/*------------------------------------------------------------
# tickets-page
------------------------------------------------------------*/
.tickets-page {
    width: 100%;
    height: 100%;
}

/*------------------------------------------------------------
# Page Header
------------------------------------------------------------*/
.tickets-page .tabs {
    margin-top: 80px;
}

.tickets-page .tabs ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.tickets-page .tabs .active {
    background: -webkit-linear-gradient(#00d0ff, #25ffbf) !important;
}

.tabs .tab-nav {
    padding: 10px 10px;
    margin-right: 0;
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    color: #040608;
    cursor: pointer;
}

.tabs .tab-nav:hover {
    background: -webkit-linear-gradient(#00d0ff, #25ffbf) !important;
}

.tickets-page .tabs .tabs-container {
    margin-top: 5px;
    box-shadow: 13px 26px 139px rgba(0, 0, 0, 0.1);
    background: #fff;
}

.tickets-page .tabs .tab-content {
    padding: 48px 15px;
    color: #989898;
    font-size: 15px;
    line-height: 1.8;
}

@media screen and (min-width: 576px){
    .tabs .tab-nav {
        padding: 20px;
    }
}

@media screen and (min-width: 992px){
    .tabs .tab-content {
        padding: 48px;
    }

    .tabs .tab-nav {
        padding: 20px 60px;
        margin-right: 10px;
        font-size: 15px;
    }
}

.tickets-page .single-event-details-row {
    margin-bottom: 30px;
}

.tickets-page .single-event-details-row:nth-last-of-type(1) {
    margin-bottom: 0;
}

.single-event-details,
.single-event-details a,
.single-event-details label {
    font-size: 18px;
    font-weight: 500;
    color: #040608;
}

.single-event-details label {
    display: block;
    margin-bottom: 0;
    color: #232127;
}

.single-event-details p,
.single-event-details a {
    color: #989898;
}

.single-event-details-row p.sold-out {
    color: #040608;
}

.single-event-details-row p.sold-out span{
    color: #ff0000;
}

.single-event-map {
    width: 100%;
    margin-top: 30px;
}

.single-event-map iframe {
    width: 100%;
    height: 460px;
}

@media screen and (min-width: 768px) {
    .tickets-page .single-event-heading {
        width: auto;
    }

    .single-event-details {
        width: 220px;
    }

    .single-event-map {
        width: calc(100% - 220px);
        margin: 0;
    }

    .event-tickets {
        padding: 40px;
    }
}

@media screen and (min-width: 992px) {
    .single-event-details {
        width: 340px;
    }

    .single-event-map {
        width: calc(100% - 340px);
        margin: 0;
    }

    .event-tickets .ticket-row {
        margin-bottom: 15px;
    }

    .ticket-type {
        width: calc(100% - 400px);
        margin-bottom: 0;
    }

    .event-tickets .number-of-ticket {
        margin-bottom: 0;
    }
}

.tickets-page .event-tickets {
    padding: 72px 48px;
    background: #fff;
    box-shadow: 13px 26px 139px rgba(0, 0, 0, 0.1);
}

.tickets-page .event-tickets .ticket-row {
    background: #f4f6f7;
    margin-bottom: 15px;
}

.tickets-page .event-tickets .ticket-row .ticket-type {
    padding: 15px 24px;
    margin-right: 15px;
}

.tickets-page .event-tickets .ticket-row .entry-title {
    font-size: 18px;
    font-weight: 500;
    color: #03070c;
    width: 235px;
    margin: 0;
    padding: 0;
}

.tickets-page .main-content .event-tickets .ticket-type span {
    font-size: 16px;
    font-weight: 500;
    color: #989898;
    margin: 0;
    padding: 0;
    width: 300px;
}

.tickets-page .main-content .event-tickets .ticket-price {
    font-size: 18px;
    font-weight: 500;
    color: #03070c;
    width: 50px;
}

.tickets-page .event-tickets .number-of-tickets {
    width: 120px;
    padding: 15px 24px;
    margin-right: 25px;
}

.tickets-page .number-of-tickets .increase-ticket,
.tickets-page .number-of-tickets .decrease-ticket {
    font-size: 18px;
    font-weight: 500;
    color: #03070c;
    cursor: pointer;
}

.tickets-page .number-of-tickets .ticket-count {
    font-size: 18px;
    font-weight: 400;
    color: #03070c;
    width: 32px;
    border: 0;
    text-align: center;
    background: #f3f8f9;
}

.tickets-page .clear-ticket-count {
    font-size: 16px;
    font-weight: 500;
    color: #989898;
    margin-right: 60px;
    cursor: pointer;
}

.tickets-page .ticket-row .btn {
    background: #f3f8f9;
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #ff2630;
}

/*------------------------------------------------------------
# gallery-page
------------------------------------------------------------*/

/*------------------------------------------------------------
# site-header
------------------------------------------------------------*/
.gallery-page nav.header-bar {
    background-color: #232127;
}

.gallery-page .main-content {
    background: #fff;
    box-shadow: 13px 14px 68px rgba(0, 0, 0, 0.1);
}

.gallery-page [class^="gallery_"] {
    padding: 250px 25px;
}

.gallery-page [class^="gallery_"] .entry-title p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: hsl(0, 0%, 48%);
}

.gallery-page [class^="gallery_"] .entry-title h2 {
    width: 100%;
    margin: 0;
    font-size: 48px;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
}

.gallery-page [class^="gallery_"] .entry-content {
    margin-top: 45px;
}

.gallery-page [class^="gallery_"] .entry-content p {
    font-size: 15px;
    color: #989898;
    line-height: 2;
    text-align: center;
}

/*------------------------------------------------------------
# contact-page
------------------------------------------------------------*/

/*------------------------------------------------------------
# site-header
------------------------------------------------------------*/
.contact-page nav.header-bar {
    background-color: #232127;
}

.contact-page .main-content {
    background: #fff;
    box-shadow: 13px 14px 68px rgba(0, 0, 0, 0.1);
}

.contact-page .get-in-touch {
    padding: 250px 100px 100px;
}

.contact-page .get-in-touch .entry-header {
    text-align: center;
}

.contact-page .get-in-touch .entry-title p {
    font-size: 14px;
    font-weight: 500;
    color: #989898;
    margin: 0;
}

.contact-page .get-in-touch .entry-title h2 {
    font-size: 48px;
    font-weight: 600;
    color: #050505;
    margin: 5px 0 0;
}

.contact-page .get-in-touch .entry-content {
    margin-top: 45px;
}

.contact-page .get-in-touch .entry-content p {
    font-size: 15px;
    color: #989898;
    line-height: 2;
    text-align: center;
}

.contact-page .main-content .get-in-touch .contact-form {
    margin-top: 120px;
}

.contact-page .contact-form input[type="text"],
.contact-page .contact-form input[type="email"],
.contact-page .contact-form textarea {
    width: 100%;
    padding: 14px 22px;
    margin-bottom: 30px;
    border: 0;
    background: #f4f6f7;
    color: #050505;
    outline: none;
}

.contact-page .contact-form form .form-group:nth-child(3) {
    margin-bottom: 30px;
}

.contact-page .contact-form label {
    color: #050505;
} 

.contact-page .contact-form input[type="text"]::placeholder,
.contact-page .contact-form input[type="email"]::placeholder,
.contact-page .contact-form textarea::placeholder {
    color: #7f7f7f;
    font-style: italic;
}

/*------------------------------------------------------------
# Ticket-page
------------------------------------------------------------*/
.ticket-page nav {
    background-color: #232127;
}

.ticket-page .main-content {
    background: #fff;
    box-shadow: 13px 14px 68px rgba(0, 0, 0, 0.1);
}

.ticket-page .main-content .iframe-container {
    padding-top: 220px;
}

/*------------------------------------------------------------
# Impressum
------------------------------------------------------------*/
.content-impressum {
    padding-top: 165px;
    padding-bottom: 1rem;
    color: #050505;
}

.content-impressum a.content-impressum-link {
    color: hsl(0, 0%, 0%);
    text-decoration: none!important;
}

.impressum-page nav.header-bar {
    background-color: #232127;
}

.impressum-page .main-content {
    background: #fff;
    box-shadow: 13px 14px 68px rgba(0, 0, 0, 0.1);
    padding: 75px;
}

/*------------------------------------------------------------
# Datenschutz
------------------------------------------------------------*/
.content-datenschutz {
    padding-top: 165px;
    padding-bottom: 1rem;
    color: #050505;
}

.content-datenschutz a.content-datenschutz-link {
    color: hsl(0, 0%, 0%);
    text-decoration: none!important;
}

.datenschutz-page nav.header-bar {
    background-color: #232127;
}

.datenschutz-page .main-content {
    background: #fff;
    box-shadow: 13px 14px 68px rgba(0, 0, 0, 0.1);
    padding: 75px;
}

/*------------------------------------------------------------
# Anfahrt element
------------------------------------------------------------*/
.anreise-headline {
    margin-top: 115px;  
}

.anreise-headline .entry-title p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: hsl(0, 0%, 48%);
    font-family: 'mountains';
}

.entry-title h2 {
    width: 100%;
    font-size: 48px;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
}

#anreise .row {
    padding-top: 80px;
}

#anreise .anreise-text,
.anreise-map .map-disclaimer {
    padding: 40px;
    background: hsl(0, 0%, 100%);
}

#anreise .anreise-text p,
#anreise .anreise-text a,
.anreise-map .map-disclaimer p,
.anreise-map .map-disclaimer a {
    font-size: 15px;
    color: hsl(0, 0%, 30%);
    line-height: 2;
}

/*------------------------------------------------------------
# tickets page
------------------------------------------------------------*/
.content-tickets {
    background-color: #232127;
    padding-top: 200px;
    padding-bottom: 1rem;
}

/*------------------------------------------------------------
# 404 page
------------------------------------------------------------*/
.content-not-found {
    background-color: #232127;
    padding-top: 165px;
    padding-bottom: 1rem;
}

.content-not-found .content-not-found-link {
    color: hsl(0, 0%, 90%);
    text-decoration: none!important;
}